
import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EligibilityService } from '../service/eligibility.service';
import { UserService } from '../service/user.service';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class EligibilityGuard {
  constructor(private eligibilityService: EligibilityService, private router: Router,private userService:UserService,private eventBus:AmdocsEventBusService) {}

  canActivate: CanActivateFn = (): Observable<boolean> | boolean => {
    const currentTime = Date.now();
    const eligibilityDuration = currentTime + CONSTANTS.CHECK_ELIGIBILITY_TIMER * 60 * 1000;
    const eligibilityDurationUserStorage = localStorage.getItem(CONSTANTS.STORAGE.eligibiltyDuration);
    let eligibilityUpdatedNeeded:boolean=false;
    let isUserEligible: any=localStorage.getItem(CONSTANTS.STORAGE.userEligibility);
    if (eligibilityDurationUserStorage && (Number(eligibilityDurationUserStorage) <= currentTime)) {
      eligibilityUpdatedNeeded=true;
    } else if(eligibilityDurationUserStorage && (!isUserEligible || isUserEligible=='false')){
      eligibilityUpdatedNeeded=true;
    }
    if (this.userService.getEmail() && !localStorage.getItem(CONSTANTS.STORAGE.isFirstLogin)  && eligibilityUpdatedNeeded) {
        let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": this.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": this.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
        return this.eligibilityService.getEligibility(userData).pipe(
          map(eligible => {
            if(eligible?.getEligibilityRuleFromAPS?.params['IsEligible']){
              localStorage.setItem(CONSTANTS.STORAGE.userEligibility,'true');
              const filterObject = eligible?.getEligibilityRuleFromAPS?.queryDefinition.filter.find(f => f.field === 'PermsSts');
              const filterValues = filterObject ? filterObject.values : [];
              const isInternal = filterValues.includes('internal')?'true':'false';
              localStorage.setItem(CONSTANTS.STORAGE.isInternal,isInternal);
              localStorage.setItem(CONSTANTS.STORAGE.eligibiltyDuration, eligibilityDuration.toString());
              setTimeout(() => {
                if(localStorage.getItem(CONSTANTS.STORAGE.isEligibleEvent)){
                  localStorage.removeItem(CONSTANTS.STORAGE.isEligibleEvent);
                }
                this.eventBus.emit('isEligible','true');
                return true;
              }, 100);
            }else {
              setTimeout(() => {
                this.eventBus.emit('isEligible','false');
              }, 1000);
              this.router.navigate(['/access-denied']);
              return true;
            }
          }),
          catchError(() => {
            this.router.navigate(['/access-denied']);
            return of(false);
          })
        );
      }else{
        localStorage.removeItem(CONSTANTS.STORAGE.isFirstLogin);
        return of(true);
      }
  };
}