import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AmdocsEventBusService } from 'amdocs-core-package';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit,OnDestroy {
  isFrame:boolean=true;
  constructor(private router:Router, private location: Location,private eventBus: AmdocsEventBusService){
  }

  ngOnInit(): void {
    this.eventBus.emit('isfixedFooter',true);
    this.checkIfInIframe();
      if(this.isFrame){   
        this.eventBus.emit('pageNotfound', true);
        this.eventBus.emit('isDocument', 'false');
        this.eventBus.emit('appLoaded', true);
        }
      else{
        this.eventBus.emit('pageNotfound', false);
        this.eventBus.emit('isDocument', 'false');
        this.eventBus.emit('appLoaded', true);
        this.eventBus.emit('isfixedFooter', true);
      }
  }

  openHomePage(){
    this.router.navigate(['/']);
  }
  
  openPreviousPage(){
    this.location.back();
  }
  ngOnDestroy(): void {
    this.eventBus.emit('isfixedFooter',false);
  }

  checkIfInIframe(): void {
    if (window.self !== window.top) {
      console.log('Page is in an iframe');
      this.isFrame=true;
      // Execute specific logic for iframe
    } else {
      console.log('Page is not in an iframe');
      this.isFrame=false;
      // Execute specific logic for non-iframe
    }
  }
}
