import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MetaDataService } from 'src/app/service/metaData.service';
import { catchError, mergeMap, of, tap, throwError } from 'rxjs';
@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss']
})

export class DocumentCardComponent implements OnInit {

  @Output() registerEvent = new EventEmitter<string>();
  @Input() productItem: any = {};
  @Input() categoryName: any
  @Input() shareCount: any = {};
  @Input() isContinueLearning: boolean;
  @Input() markasNeeded: boolean;
  @Input() completedIcon: boolean;
  @Input() isSelf: boolean;
  @Input() planData: any = {}
  @Input() isdocumentDetail: boolean = false;
  @Input() isProductTree: boolean = false;
  @Input() searchInput: string = '';
  public totalHoursCard: any;
  public pitemDetails: any = [];

  isPopoverVisible: boolean = false;
  loader: boolean = false;
  public amdocsUData: any;
  public assetsPath = CONSTANTS.ASSETS_URL;
  public hrefLink: string;
  public showTooltip: boolean = false;
  public audienceTypeInternal: any = localStorage.getItem(CONSTANTS.STORAGE.isInternal);
  selectedItems: any[] = [];
  seeMore: boolean = false;
  public count = CONSTANTS.SHARE_LIMIT;
  public openMenu:string='';
  showPopup: boolean = false;
  @Input() cardIndex: any = 0;
  @Input() productLine: string = '';
  constructor(private eventBusService: AmdocsEventBusService, private cookieService: CookieService, private router: Router, private metaData: MetaDataService) { }
  ngOnInit() {
    this.pitemDetails = this.productItem
  }

  open(url: string, id: string) {
    this.hrefLink = window.location.origin;
    const docID = id;
    const Idurl = url.split("Shared Documents/");
    const startpage = Idurl[1].split('/').slice(1).join('/')
    window.open(`${this.hrefLink}/document?DocUID=${docID}&SOSessionID=&StartPage=${startpage}`, '_blank');
  }
  opendocument(id: string, fileType: string,productLine:string) {
    if (fileType.toLowerCase() == 'html') {
      this.hrefLink = window.location.origin;
      const docID = id;
      window.open(`${this.hrefLink}/document?DocUID=${docID}&productLine=${encodeURIComponent(productLine)}&SOSessionID=`, '_blank');
    } else {
      this.getMetaData(id);
    }

  }
  openOptions(e: any, data: any,id:any) {
    this.openMenu=id;
    this.eventBusService.emit(CONSTANTS.EVENTS.OPEN_OPTION_MODAL, { 'evnt': e.target, 'isLearning': true, 'markasNeeded': true, 'completedIcon': true, 'courseData': true, 'isProgram': true, 'data': data,'id':this.openMenu });
  }

  showPopover(e) {
    this.isPopoverVisible = true;
  }

  hidePopover() {
    this.isPopoverVisible = false;
  }
  
  checkIfTruncated(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const originalStyle = element.style.cssText;

    // Temporarily remove the CSS properties
    element.style.webkitLineClamp = 'unset';
    element.style.webkitBoxOrient = 'unset';
    element.style.display = 'block';

    // Measure the height of the content
    const fullHeight = element.scrollHeight;

    // Reapply the CSS properties
    element.style.cssText = originalStyle;

    const maxHeight = CONSTANTS.CardTitleHeight; // Maximum height for two lines
    if (fullHeight > maxHeight) {
      this.showTooltip = true;
      this.showPopup = true;
    } else {
      this.showTooltip = false;
      this.showPopup = false;
    }
  }
  

  getFileIcon(fileType: string) {
    switch (fileType?.toUpperCase()) {
      case 'PDF':
        return `${this.assetsPath}pdf.svg`;
      case 'EXCEL':
      case 'EXCELTEXTREPORT':
        return `${this.assetsPath}excel.svg`;
      case 'WORD':
      case 'MSW12':
      case 'MSW8':
        return `${this.assetsPath}word.svg`;
      case 'INTERNAL':
        return `${this.assetsPath}internal.svg`;
      case 'ZIP':
      case 'APPLICATION/ZIP':
        return `${this.assetsPath}zip.svg`;
      default:
        return `${this.assetsPath}globe.png`;
    }
  }

  onCheckboxChange($event, item: any) {
    const checkbox = $event.target as HTMLInputElement;
    const isChecked = checkbox.checked;

    item.isDisabled = !item.isDisabled;
    item.isChecked = !item.isChecked;
    if (this.isdocumentDetail) {
      this.eventBusService.emit(CONSTANTS.EVENTS.CHECKBOX_SELECTED_DOCUMENT, item);
    }
    else {
      this.eventBusService.emit(CONSTANTS.EVENTS.CHECKBOX_SELECTED, { item: item, isChecked: isChecked, categoryName: this.categoryName });
    }

  }


  capitalizeFirstLetter(text: any): any {
    if (Array.isArray(text)) {
      return text.map(item => this.capitalizeFirstLetter(item));
    } else if (typeof text === 'string') {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
      console.error('Input is not a string or array of strings:', text);
      return text;
    }
  }

  seeMoreDocuments(UnqI: string, searchInput: string, item: any) {
    this.loader = true;
    if (item.innerSearch && item.innerSearch?.length > 0) {
      this.seeMore = !this.seeMore;
      this.loader = false;
    } else {

      const DocUID = UnqI;

      this.metaData.getDocumentInternalSearchData(DocUID, searchInput)
        .pipe(
          tap((response: any) => {
            this.seeMore = !this.seeMore;
            this.loader = false;
            if (response['getDoumentById'
            ]?.fusionDoumentResponse?.results?.list?.docs) {

              item.innerSearch = response['getDoumentById'
              ]?.fusionDoumentResponse?.results?.list?.docs;
            }
            else {
              item.innerSearch = [];
            }

          }),
          catchError((error: any) => {
            this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
            return throwError(() => error);
          })
        ).subscribe();
    }
  }

  removeNA(data: string) {
    return data?.split('>')
      .filter(part => part.trim() !== 'NA')
      .join('>')
      .replace(/>+/g, ' > ');
  }

  getMetaData(id: string) {
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, true);
    const DocUID = id;
    const SOSessionID = '';

    this.metaData.getDocumentMetaData(DocUID, SOSessionID)
      .pipe(
        mergeMap((response: any) => {
          this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
          if (response['getDoumentMetaDataById'
          ]?.nonHtmlDocumentUrl) {

            window.open(response['getDoumentMetaDataById'
            ].nonHtmlDocumentUrl, "_blank")
          }
          return of(response);
        }),
        catchError((error: any) => {
          this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
          return throwError(() => error);
        })
      ).subscribe();

  }

  getHighlights(SNPT: any) {
    let snpt = '';
    SNPT?.forEach(element => {
      let details = element.value + '... ';
      snpt = snpt + details;
    });
    return snpt;
  }

  seeLessDocuments() {
    this.seeMore = !this.seeMore;
  }

  formatData(data: any): string {
    if (Array.isArray(data)) {
      return data.map(v => this.addSpaceAfterComma(v)).join(', ');
    }
    return 'N/A';
  }

  addSpaceAfterComma(value: string): string {
    return typeof value === 'string' ? value.replace(/,/g, ', ') : value;
  }
}


