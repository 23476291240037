
<div class="course-card box" id="{{cardIndex}}" data-qa="cards">
    <div class="box-content">
        <div class="d-flex justify-content-between">

            <div><input data-qa="card-input" class="checkmark"
                    [checked]="isdocumentDetail? pitemDetails.isChecked : pitemDetails.fields.isChecked"
                    [disabled]="isdocumentDetail? pitemDetails.isDisabled!=undefined && pitemDetails.isDisabled : pitemDetails.fields.isDisabled && shareCount==count"
                    (change)="onCheckboxChange ($event,pitemDetails.fields)" type="checkbox" id="prdline" name="pdline"
                    value="cards">
                <label for="prdline"></label>
            </div>
            <div class="d-flex">
                <div class="openOptions" data-qa="menu-option" (click)="openOptions($event,pitemDetails.fields,cardIndex);"><img class="openOptionsSvg"
                        src="{{assetsPath}}options.svg" alt="menu-icon" /></div>
            </div>
        </div>
        <div class="course-name" (click)="opendocument(pitemDetails.fields.UnqI, pitemDetails.fields.FileT,this.productLine);">
            <div class="course-category">
              <div *ngIf="showPopup" class="courseDetails" data-qa="product-name">
                {{ pitemDetails?.fields?.KIN[0] }}
              </div>

              <h3 data-qa="product-name-tooltip"  *ngIf="!isdocumentDetail" (mouseenter)="checkIfTruncated($event)"
              (mouseleave)="showPopup = false"
              [matTooltip]="showTooltip ? text : ''"
              matTooltipPosition="above">
                {{ pitemDetails?.fields?.KIN[0] }}
              </h3>

                 <h3  class="searchCard" *ngIf="isdocumentDetail  && !searchInput" innerHTML="{{pitemDetails?.fields?.KIN}}"></h3>
                 <h3 class="searchCard" *ngIf="isdocumentDetail && searchInput" innerHTML="{{ pitemDetails?.highlights?.KIN ? pitemDetails?.highlights?.KIN[0].value : pitemDetails?.fields?.KIN}}"></h3>

                <div>
                    <div  data-qa="product-snpt" *ngIf="isdocumentDetail && pitemDetails?.fields?.SNPT && !searchInput"
                        innerHTML="{{ pitemDetails?.fields?.SNPT}}"></div>

                    <div *ngIf="isdocumentDetail && pitemDetails?.highlights?.SNPT && searchInput">
                        <p  data-qa="product-snpt" *ngIf="pitemDetails.fields.FileT.toLowerCase() == 'html'" innerHTML="{{getHighlights(pitemDetails?.highlights?.SNPT)}}"
                        (click)="open(pitemDetails?.fields?.VSK,pitemDetails.fields.UnqI); $event.stopPropagation()"></p>
                        <p  data-qa="product-snpt" *ngIf="pitemDetails.fields.FileT.toLowerCase() != 'html'" innerHTML="{{getHighlights(pitemDetails?.highlights?.SNPT)}}"></p>
                        <span class="seeMore" data-qa="seeMoreButton-snpt" *ngIf="isdocumentDetail &&  pitemDetails?.highlights?.SNPT &&
                    pitemDetails?.groupedDocs && searchInput && !seeMore && pitemDetails?.fields?.FileT.toLowerCase() == 'html'"
                            (click)="seeMoreDocuments(pitemDetails.fields.UnqI, searchInput, pitemDetails); $event.stopPropagation()">See
                            more</span>
                    </div>
                    <div class="seeMoreDiv" data-qa="seeMoreData-snpt" *ngIf="isdocumentDetail && searchInput && pitemDetails?.fields?.SNPT && pitemDetails?.innerSearch &&
                    pitemDetails?.innerSearch.length > 0 && seeMore">


                        <div *ngFor="let group_data of pitemDetails?.innerSearch;"
                            (click)="open(group_data.fields.VSK,pitemDetails.fields.UnqI); $event.stopPropagation()">
                            <hr *ngIf="group_data?.highlights?.SNPT" />
                            <p *ngIf="group_data?.highlights?.SNPT" innerHTML="{{getHighlights(group_data?.highlights?.SNPT)}}"></p>
                        </div>
                        <span class="seeMore" *ngIf="seeMore" (click)="seeLessDocuments(); $event.stopPropagation()">See
                            less</span>
                    </div>

                <div>
                  <div data-qa="productTree" class="tree" *ngIf="isdocumentDetail && pitemDetails.fields.APrdctTre && isProductTree">
                    {{ removeNA(pitemDetails.fields.APrdctTre[0]) }}
                    <div data-qa="productTreeCount" *ngIf="pitemDetails.fields.APrdctTre.length > 1" class="filter-count">
                      {{pitemDetails.fields.APrdctTre.length}}
                    </div>
                    <div data-qa="productTreeHover" class="product-tree" *ngIf="isProductTree && pitemDetails.fields.APrdctTre.length > 1">
                      <p *ngFor="let tree_data of pitemDetails.fields.APrdctTre;">
                        {{ removeNA(tree_data) }}
                      </p>
                    </div>
                  </div>
                    <p data-qa="productVersion"><span>Version:</span>  {{ formatData(pitemDetails.fields?.FVN) }}</p>
                    <p  data-qa="productName" *ngIf="!isdocumentDetail"><span>Product:</span> {{ formatData(pitemDetails.fields?.Prdct) }}</p>
                    <p data-qa="productPublished"><span>Published:</span> {{pitemDetails.fields?.LstUpdt|dateFormat}} | <img
                            [src]="getFileIcon(pitemDetails.fields.FileT)" alt="file-icon" /><span
                            class="details">{{pitemDetails.fields.FileT}}</span>
                        <span class="divider" *ngIf="pitemDetails?.fields?.PermsSts!='external' &&  audienceTypeInternal">|</span> <img
                            *ngIf="pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal" src="/assets/icons/internal.svg"
                            alt="file-icon" /><span *ngIf="pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal"
                            class="details">{{pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal?
                            capitalizeFirstLetter(pitemDetails?.fields?.PermsSts) : ''}}</span>
                    </p>
                </div>

                </div>
            </div>
        </div>

        <app-amdocs-loader  *ngIf="loader" class="loader"></app-amdocs-loader>
