<footer class="text-center" data-qa="footer-section">
  <div class="p-4 footerbg d-flex justify-content-evenly">
    <div class="footerbg" data-qa="footer-copyright">
      <a href="{{copyRightsUrl}}" target="_blank"  rel="noopener">Copyright © {{currentYear}} by Amdocs</a>
    </div>
    <div class="footerbg" (click)="showTerms()" data-qa="footer-terms">
      <a>Terms & Conditions</a>
    </div>
    <div class="footerbg" data-qa="footer-amdocsUrl">
     <a href="{{amdocsUrl}}" target="_blank"  rel="noopener">www.amdocs.com</a>
    </div>
  </div>
</footer>
