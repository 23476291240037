export const misnpBaseURL = 'https://misnp.amdocs.com';
export const misBaseURL = 'https://mis.amdocs.com';
export const baseEnvironment = {
    loginGWBaseURL: '',
    graphQlBaseURL: '/graphql',
    cnsBaseURL: '',
    avatarsVersion: '1',
    refreshTokenInterval: 2400000,
    appId: 'dac',
    footerConfig:{
      copyrights:'',
      termsConditions:'',
      wwwAmdocs:''
    },
    headerConfig:{
      supportOne:''
    },
    dacSessionId:''
  };
