  import {  Component, HostListener, Input } from '@angular/core';
import { filter } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

  @Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
  })
  export class BannerComponent {

    @Input() title: string = 'Welcome to Amdocs product documentation';
    @Input() img: string = 'home_banner.png';
    @Input() topSmallText: boolean = false;
    public isHomeScreen = false;
    public isShrink:boolean=false;
    public routePage:string='';
    constructor(private router:Router){
      this.routePage = this.router.url;
      this.updateShrinkState(this.routePage);
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.routePage=event.url;
        if(this.routePage=='/'){
          this.isShrink=false;
        }else{
          this.isShrink=true;
        }
    });
  }

    @HostListener('window:scroll', ['$event']) 
    getScrollPosition(event:any) {
      if(this.routePage=='/' || this.routePage==''){
      let shrinkHeader = 250;
      if ( window.scrollY >= shrinkHeader ) {
          this.isShrink=true;
      }
      if(window.scrollY==0  && this.isShrink){
        this.isShrink=false;
      }
    }else{
      this.isShrink=true;
    }
    }
  
    private updateShrinkState(url: string): void {
      if (url === '/') {
        this.isShrink = false;
      } else {
        this.isShrink = true;
      }
    }

  }
