
  <div class="sidebar-fade" data-qa="sidebar-fade" [ngClass]="{'show': menuOpen}" (click)="close()"></div>
  <div data-qa="sidebar-favaourites-button" id="sidebar-menu-wrapper" [ngClass]="{'open': menuOpen}" (click)="$event.stopPropagation();">
    <div class="container" data-qa="sidebar-favaourites-menu">
      <div class="favorites" data-qa="favorite-section">
        <div class="box">
          <div class="d-flex justify-content-between">
          <h3>My favorite product lines</h3>
          <img alt="folder-icon" class="close" (click)="close()" src="/assets/icons/close.svg" />
          </div>
          <hr class="custom-hr" />
          <ng-container *ngIf="markedFavProducts?.length==0 && !isApiLoading">
            <div class="def-col" data-qa="no-favourite">
              <span>No favorites yet</span>
              <img alt="folder-icon" src="/assets/icons/folder.svg" />
              <p>
                Hover over a product line and click the star to add it here for
                quick access.
              </p>
            </div>
          </ng-container>

          <ul *ngIf="markedFavProducts" data-qa="favouriteList">
            <ng-container *ngFor="let favProduct of markedFavProducts">
              <li *ngIf="favProduct.name!='' && !isApiLoading" class="fav_active">
            <span class="starfav" data-qa="starfavourite" (click)="removeFavourites(favProduct, false)"></span>
            <span data-qa="favouritenavigation" (click)="getProductLine(favProduct)"> {{ favProduct.name }}</span>
            </li>
            </ng-container>
          </ul>
        </div>
  </div>
  </div>

  <app-amdocs-loader *ngIf="isApiLoading" class="loader"></app-amdocs-loader>
