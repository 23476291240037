
export const CONSTANTS = {
  SHARE_LIMIT:6,
  TOAST_TIME:10000,
  SHARE_LIMIT_MESSAGE:'You can select up to 6 documents at a time. You can download or share the selected documents or deselect some documents to proceed.',
    EVENTS: {
      OPEN_OPTION_MODAL:'open-option-modal',
      PAGINATOR:'paginator',
      CHECKBOX_SELECTED:'checkboxSelected',
      CHECKBOX_SELECTED_DOCUMENT:'checkboxSelectedDocument',
      DOCUMENT_LOADER:'document_loader',
      PAGE_LOADER:'pageloader',
      ACCESSDENIED:'accessdenied',
      IS_ELIGIBLE:'isEligible'
    },
    ASSETS_URL:"/assets/icons/",
    NoCategoryNaming:'Miscellaneous',
    CardTitleHeight:68,
    PageTitle:
      {
        HomePage:'Amdocs Product Documentation',
        ProductLine:'Amdocs Product Documentation : ',
        SearchResults:'Search Results',
        DocumentPage:'Document',
        AccessDeniedPage:'Access Denied',
        PageNotFound:'Page Not Found'

      },
    CHECK_ELIGIBILITY_TIMER:60,
    STORAGE:{
      userEligibility:'userEligibility',
      isInternal:'isInternal',
      isFirstLogin:'isFirstLogin',
      isProductLine:'isProductLine',
      eligibiltyDuration:'eligibiltyDuration',
      documentMessage:'documentMessage',
      isEligibleEvent:'isEligibleEvent',
      isdocumentEligible:'isdocumentEligible',
    }
      

}

