  import { Component, OnDestroy, OnInit } from '@angular/core';
  import { ActivatedRoute, NavigationStart, Router, Event, NavigationEnd } from '@angular/router';
  import { AmdocsEventBusService } from 'amdocs-core-package';
  import { Observable, Subscription, catchError, filter, mergeMap, of, throwError } from 'rxjs';
  import { MetaDataService } from './service/metaData.service';
  import { NetworkService } from './service/network.service';
  import { EligibilityService } from './service/eligibility.service';
  import { UserService } from './service/user.service';
  import { CONSTANTS } from './constants';
  import { environment } from 'src/environments/environment';

  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
  })
  export class AppComponent implements OnInit, OnDestroy {
    title = 'Doc_As_A_Code';
    public showDocumentBanner: boolean = false;
    public appLoaded: boolean = true;
    isEligible: any = '';
    public url: any;
    public showTermsDialog: boolean = false;
    public defTitle: string = '';
    public isDocument: any = '';
    public isValidDocument:boolean=false;
    startUrl: string;
    endUrl: string;
    public getMeta:boolean=false;
    public pageNotfound:boolean=false;
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    isOnline: boolean;
    subscriptions: Subscription[] = [];
    public networkStatusSubscription: Subscription;
    public isuserOffline=false;
    public isOff:boolean=false;
    private docsUrl:string='';
    networkStatus: boolean = false;
    onlineStatus: boolean;
    vpnConnected: boolean = true;
    isProductLine:boolean=false;
    isUserEligible:boolean=false;
    public isBannerPage:boolean=true;
    public productLineLoaded:boolean=false;
    public hrefLink: string;
    public docUId: any;
    public sOSessionID: any;
    public noFooter:boolean=false;
    public isfixedFooter:boolean=false;
    public apiError:any='';
    isWildcardRoute: boolean = false;
    isLocalServer=environment.dacSessionId;
    private eventSubscriptions: any[] = [];
    constructor(private router: Router, private route: ActivatedRoute, private eventBus: AmdocsEventBusService, private metaData: MetaDataService,private networkService: NetworkService,private elService: EligibilityService,private userService: UserService) {
      this.eventBus.on(CONSTANTS.EVENTS.PAGE_LOADER, (data: any) => {
        this.productLineLoaded = data;
      }, true);

      this.eventBus.on(CONSTANTS.EVENTS.ACCESSDENIED, (data: any) => {
        this.isUserEligible = false;
        this.noFooter=true;
      }, true);
      this.appLoaded = false;
      this.eventBus.on(CONSTANTS.EVENTS.IS_ELIGIBLE, (data: any) => {
        this.isUserEligible=data=='true'?true:false;
        let docId = sessionStorage.getItem("DocUID");
        if(data=='true' && docId){
        this.getMetaData();
        this.getMeta=true;
        }
        else if(data=='true' && !docId){
          this.isDocument='false';
          this.isfixedFooter=false;
          this.isUserEligible=true;
          setTimeout(() => {
            this.eventBus.emit('docsRlError','noerror');
          }, 1000);
        }
        else{
        this.isDocument='false';
        this.isfixedFooter=true;
        this.isUserEligible=false;
        setTimeout(() => {
          this.eventBus.emit('docsRlError','error');
        }, 1000);
        }
      }, true);
      const eventMappings = [
        { event: 'appLoaded', property: 'appLoaded', additionalProperty: 'isDocument' },
        { event: 'showDocument', property: 'showDocumentBanner' },
        { event: 'pageNotfound', property: 'pageNotfound' },
        { event: 'showterms', property: 'showTermsDialog' },
        { event: 'isfixedFooter', property: 'isfixedFooter' },
        { event:'global-api-error-event',property:'apiError'},
      ];

      eventMappings.forEach(mapping => {
        const subscription = this.eventBus.on(mapping.event, (data: any) => {
          this[mapping.property] = data;
          if (mapping.additionalProperty) {
            this[mapping.additionalProperty] = data;
          }
        }, true);
        this.eventSubscriptions.push(subscription);
      });
      if (window.location.href.indexOf('_callback') > -1 &&  sessionStorage.getItem("isdocumentCallback")) {
        this.router.navigate(['/document'])
      }
      if (window.location.href.indexOf('_callback') > -1 &&  sessionStorage.getItem("shareId")) {
        const id = sessionStorage.getItem("shareId")
        const redirectLink = `/searchresults?searchID=${id}`;
        this.router.navigateByUrl(redirectLink);
      }
      this.eventBus.on('online', (data: any) => {
        let elgData=this;
        setTimeout(() => {
          let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": elgData.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": elgData.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
        elgData.elService.getEligibility(userData)
        .pipe(
          mergeMap((response: any) => {
            if(response)
            this.isOff=false;
            return of(response);
          }),
          catchError((error: any) => {
            return throwError(() => error);
          })
        ).subscribe();
        }, 1000);

      });
    }
    ngOnInit(): void {
      this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((event: NavigationStart) => {
        this.eventBus.emit('pageloader', true);
        this.startUrl=event.url;

      });
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.checkForWildcardRoute();
        this.endUrl=event.url;
        const isEl = localStorage.getItem(CONSTANTS.STORAGE.userEligibility);
        if(this.endUrl=='/productline'){
          this.isProductLine=true;
        }else{
          this.isProductLine=false;
        }
        if(this.endUrl.indexOf('document')>0){
          let docId = sessionStorage.getItem("DocUID");
          let soId = sessionStorage.getItem("SOSessionID");
          if(docId  && this.endUrl.indexOf('/document')==0 && (this.endUrl===this.startUrl) && localStorage.getItem(CONSTANTS.STORAGE.isdocumentEligible)=='false'){
            this.noFooter=true;
          }
          if (docId  && (isEl=='false' || isEl=='true') && this.endUrl.indexOf('/document')==0 && (this.endUrl===this.startUrl)) {
            this.setMetaData();
          }
          else if(!docId && !soId  && this.endUrl.indexOf('/document')==0 && (this.endUrl===this.startUrl)){
            this.isDocument='false';
            this.appLoaded = true;
            this.eventBus.emit('docsRlError','error');
            this.noFooter=true;
          }
          else{
            this.isDocument='false';
          }
        }
        if((this.endUrl===this.startUrl) && this.endUrl.indexOf('Shared%20Documents')<0 &&  this.endUrl.indexOf('document')<0){
          sessionStorage.removeItem("DocUID");
          sessionStorage.removeItem("SOSessionID");
          sessionStorage.removeItem("StartPage");
          this.isDocument='false';
        }
        let docId = sessionStorage.getItem("DocUID");
        if (docId && isEl=='true' && this.endUrl=='/') {
          this.router.navigate(['/document']);
        if(window.self !== window.top){
          this.eventBus.emit('pageNotfound',true);
        }
        }
        this.isUserEligible=localStorage.getItem(CONSTANTS.STORAGE.userEligibility)=='true'?true:false;
        setTimeout(() => {
        this.eventBus.emit('pageloader', false);
      }, 1000);
      });
      this.networkStatusSubscription = this.networkService.isOnline$.subscribe(
        (isOnline) => {
          this.isOnline = isOnline;
          if(!this.isOnline && !this.isOff){
            this.isOff=true;
          }
          if(this.isOnline && this.isOff){
            this.isOff=false;
            let elgData=this;
            setTimeout(() => {
              let userData= {
                "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
                "User_ID": elgData.userService.getEmail(),
                "IP_Details": "",
                "Emp_ID": elgData.userService.getEmployeeCode(),
                "DaC_Session_ID": ""

            }
            this.eventBus.emit('IsOffSubscription',true);
            elgData.elService.getEligibility(userData)
            .pipe(
              mergeMap((response: any) => {
                if(response){
                  let isElig:any='false';
                  if(response.getEligibilityRuleFromAPS.params['IsEligible']){
                    isElig='true';
                    setTimeout(() => {
                      location.reload()
                    },2000);
                  }
                  localStorage.setItem(CONSTANTS.STORAGE.userEligibility,isElig);
                  this.eventBus.emit('IsOffSubscription',true);
                }
                return of(response);
              }),
              catchError((error: any) => {
                this.eventBus.emit('IsOffSubscription',true);
                return throwError(() => error);
              })
            ).subscribe();
            }, 1000);
          }
        }
      );
    }

    setMetaData(){
      this.appLoaded = false;
      let userData= {
        "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
        "User_ID": this.userService.getEmail(),
        "IP_Details": "",
        "Emp_ID": this.userService.getEmployeeCode(),
        "DaC_Session_ID": ""

    }
    this.eventBus.emit('IsOffSubscription',true);
    this.elService.getEligibility(userData)
    .pipe(
      mergeMap((response: any) => {
        if(response){
          let isElig:any='false';
          if(response.getEligibilityRuleFromAPS.params['IsEligible']){
            isElig='true';
          }
          localStorage.setItem(CONSTANTS.STORAGE.userEligibility,isElig);
        return this.metaData.getDocumentMetaData(sessionStorage.getItem("DocUID"), sessionStorage.getItem("SOSessionID")?sessionStorage.getItem("SOSessionID"):'')
        }
      }),
      catchError((error: any) => {
        // Handle the error for the first API call
        this.eventBus.emit('IsOffSubscription',true);
        console.log('Catch Error');
        return throwError(() => error);
      })
    )
    .subscribe({
      next: (response: any) => {
        if(response['getDoumentMetaDataById'
        ]?.nonHtmlDocumentUrl){
          window.open(response['getDoumentMetaDataById'
          ].nonHtmlDocumentUrl,"_self")
        }
        else{
        this.isValidDocument=true;
        if (response && response['getDoumentMetaDataById'
      ]!=null && response['getDoumentMetaDataById'
      ]['fusionDoumentResponse']!=null && response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results'] !== null) {
          this.defTitle = response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields'];
          this.url = window.location.origin + response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields']?.['SPUrl']+'?ts='+ Date.now();
          this.docsUrl=this.url;
          let startPage = decodeURIComponent(sessionStorage.getItem("StartPage"));
          const expires = response?.['getDoumentMetaDataById']?.['expires'];
              if(expires){
                sessionStorage.setItem('documentExpiration',expires);
              }

          if(sessionStorage.getItem("StartPage")){
              let directoryPath = response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields']?.['SPUrl']?.[0];
              let parts = directoryPath ? directoryPath.split('/'):'';
              let firstPart = parts.slice(0, 3).join('/');
              if(firstPart){
                this.url = window.location.origin +firstPart+'/'+startPage+'?ts='+ Date.now();
              }
          }
          this.isDocument = 'true';
          this.eventBus.emit('docsUrl',{'frameUrl':this.url,'documentUrl':this.docsUrl});
          if(window.self !== window.top){
            this.eventBus.emit('pageNotfound',true);
            }

        }else{
          this.eventBus.emit('docsUrlError','error');
          this.appLoaded = true;
          this.isDocument = 'true';
          this.isValidDocument=false;
        }
       }
      },
      error: (error: any) => {
        this.eventBus.emit('IsOffSubscription',true);
        console.error('Custom Error:', error);
        this.appLoaded = true;
      }
    });
    }

    closeError(){
      this.apiError='';
    }

    getMetaData() {
    const DocUID =  sessionStorage.getItem("DocUID")
    const SOSessionID= sessionStorage.getItem("SOSessionID")
    if(DocUID){
      this.docUId = DocUID
    }
    if(SOSessionID){
      this.sOSessionID =SOSessionID
    }
    else{
      this.sOSessionID = ' ';
    }

      this.appLoaded = false;
      this.metaData.getDocumentMetaData(this.docUId, this.sOSessionID)
        .pipe(
          mergeMap((response: any) => {
            if(response['getDoumentMetaDataById'
            ]?.nonHtmlDocumentUrl){
              window.open(response['getDoumentMetaDataById'
              ].nonHtmlDocumentUrl,"_self")
            }
            else{
              this.isValidDocument=true;
              if (response && response['getDoumentMetaDataById'
              ]!=null && response['getDoumentMetaDataById'
              ]['fusionDoumentResponse']!=null && response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results'] !== null) {
                  this.defTitle = response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields'];
                  this.url = window.location.origin + response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields']?.['SPUrl']+'?ts='+ Date.now();
                  this.docsUrl=this.url;
                  let startPage = decodeURIComponent(sessionStorage.getItem("StartPage"));
                  const expires = response?.['getDoumentMetaDataById']?.['expires'];
                      if(expires){
                        sessionStorage.setItem('documentExpiration',expires);
                      }

                    if(sessionStorage.getItem("StartPage")){
                      let directoryPath = response?.['getDoumentMetaDataById']?.['fusionDoumentResponse']?.['results']?.['list']?.['docs']?.[0]?.['fields']?.['SPUrl']?.[0];
                      let parts = directoryPath.split('/');
                      let firstPart = parts.slice(0, 3).join('/');
                      if(firstPart){
                      this.url = window.location.origin +firstPart+'/'+startPage+'?ts='+ Date.now();
                    }
                  }
                this.isDocument = 'true';
                this.eventBus.emit('docsUrl',{'frameUrl':this.url,'documentUrl':this.docsUrl});
                if(window.self !== window.top){
                  this.eventBus.emit('pageNotfound',true);
                }

              }else{
                this.eventBus.emit('docsUrlError','error');
                this.appLoaded = true;
                this.isDocument = 'true';
                this.isValidDocument=false;
              }
            }

            return of(response);
          }),
          catchError((error: any) => {
            return throwError(() => error);
          })
        ).subscribe();

    }

    checkForWildcardRoute(): void {
      let currentRoute = this.route.snapshot;
      currentRoute = currentRoute.firstChild;
      this.isWildcardRoute = !currentRoute.routeConfig || currentRoute.routeConfig.path === '**';
    }

    ngOnDestroy(): void {
      this.eventSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
