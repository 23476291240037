import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
import { EligibilityService } from 'src/app/service/eligibility.service';
import { TitleService } from 'src/app/service/title.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-accessDeenied',
  templateUrl: './accessDenied.component.html'
})
export class AccessDeniedComponent{

  constructor(private eventBus: AmdocsEventBusService,private router: Router,private userService:UserService,private eligibilityService:EligibilityService,private titleService:TitleService) {
     this.eventBus.emit('appLoaded',true);
     this.checkisEligible();
  }

  ngOninit(){
    this.setTitle();
  }

  checkisEligible() {
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
        if (navigation.type == 'reload') {
          let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": this.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": this.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
      this.eligibilityService
      .getEligibility(userData)
      .subscribe((eligible) => {
        if(eligible?.getEligibilityRuleFromAPS?.params['IsEligible']){
          localStorage.setItem(CONSTANTS.STORAGE.isProductLine,'1');
          localStorage.setItem(CONSTANTS.STORAGE.userEligibility,'true');
          this.router.navigate(['/']);
          }
      }); 
      
    }
  }

  setTitle(): void {
    const staticTitle = CONSTANTS.PageTitle['AccessDeniedPage']; // Set your static title here
    this.titleService.setTitle(staticTitle);
  }
  
}
