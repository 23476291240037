
    <div *ngIf="ismodalOpen" class="cardmenu-fade"  (click)="toggleModal()"></div>
    <div style="position:absolute; width: -webkit-fill-available; width: -moz-available; " class="filter-search">
        <div class="loader-background" *ngIf="isFilterApplied">
        <app-amdocs-loader  class="loader"></app-amdocs-loader>
        </div> 
        <div class="modal" [hidden]="!ismodalOpen">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title d-flex">Filters</h2>
                    <span class="close-btn" (click)="toggleModal()"><img alt="closIcon" width="12px" src="/assets/icons/close-icon.svg"></span>
                </div>
                <div id="filterDiv" #filterDiv>
                <div class="product-selection" data-qa="product-lines">
                    <p>Product lines</p>
                    <amdocs-autocomplete #selectProductLines
                    [isMultiple]="true"
                    [hasHierarchy]="true"
                    [customClass]="'new-design'"
                    [isRequired]="true"
                    [items]="productLines"
                    [hasSearchBox]="true"
                    [searchPlaceholder]="'Find a product line'"
                    [hideLabel]="true"
                    [clearable]="true"
                    [placeholder]="'Select'"
                    [isPlaceHolder]="false"
                    [isApplyButton]="true"
                    [selectedItemCode]="urlproductLineData"
                    (itemChangedCallback)="getProductLines($event)"
                    [id]="'productLineId'"
                    (applyFilterCallback)="resetFilter($event)"
                    [isApply]="isApplied"
                    ></amdocs-autocomplete></div>

            <div class="product-selection" data-qa="products">
                <p>Products</p>
                <amdocs-autocomplete #selectProduct
                [isMultiple]="true"
                [hasHierarchy]="true"
                [customClass]="'new-design'"
                [isRequired]="true"
                [items]="productItems"
                [hasSearchBox]="true"
                [searchPlaceholder]="'Find a product'"
                [hideLabel]="true"
                [clearable]="true"
                [placeholder]="'Select'"
                [isPlaceHolder]="false"
                [isApplyButton]="true"
                [selectedItemCode]="urlproductsData"
                (itemChangedCallback)="getProducts($event)"
                [id]="'productId'"
                (applyFilterCallback)="resetFilter($event)"
                [isApply]="isApplied"

                ></amdocs-autocomplete></div>
        <div class="product-selection" data-qa="components">
            <p>Components</p>
            <amdocs-autocomplete #selectComponents
            [isMultiple]="true"
            [hasHierarchy]="true"
            [customClass]="'new-design'"
            [isRequired]="true"
            [items]="componentItems"
            [hasSearchBox]="true"
            [searchPlaceholder]="'Find a component'"
            [hideLabel]="true"
            [clearable]="true"
            [placeholder]="'Select'"
            [isPlaceHolder]="false"
            [isApplyButton]="true"
            [selectedItemCode]="urlcomponentsData"
            (itemChangedCallback)="getComponents($event)"
            [id]="'componentId'"
            (applyFilterCallback)="resetFilter($event)"
            [isApply]="isApplied"


            ></amdocs-autocomplete></div>
    <div class="product-selection" data-qa="product-versions">
        <p>Versions</p>
        <amdocs-autocomplete #selectVersions
        [isMultiple]="true"
        [hasHierarchy]="true"
        [customClass]="'new-design'"
        [isRequired]="true"
        [items]="versionItems"
        [hasSearchBox]="true"
        [searchPlaceholder]="'Find a version'"
        [hideLabel]="true"
        [clearable]="true"
        [placeholder]="'Select'"
        [isPlaceHolder]="false"
        [isApplyButton]="true"
        [selectedItemCode]="urlversionsData"
        (itemChangedCallback)="getVersions($event)"
        [id]="'versionId'"
        (applyFilterCallback)="resetFilter($event)"
        [isApply]="isApplied"

        ></amdocs-autocomplete></div>
        <div class="product-selection" data-qa="product-document">
            <p>Document types</p>
            <amdocs-autocomplete #selectDocument
            [isMultiple]="true"
            [hasHierarchy]="true"
            [customClass]="'new-design'"
            [isRequired]="true"
            [items]="docTypeItems"
            [hasSearchBox]="true"
            [searchPlaceholder]="'Find a document type'"
            [hideLabel]="true"
            [clearable]="true"
            [placeholder]="'Select'"
            [isPlaceHolder]="false"
            [isApplyButton]="true"
            [selectedItemCode]="urldocumentTypeData"
            (itemChangedCallback)="getDocType($event)"
            [id]="'docTypeId'"
            (applyFilterCallback)="resetFilter($event)"
            [isApply]="isApplied"

            ></amdocs-autocomplete></div>
            <div *ngIf="audienceTypeInternal=='true'" class="product-selection" data-qa="product-audience">
                <p>Audience</p>
                <amdocs-autocomplete #selectAudience
                [isMultiple]="true"
                [hasHierarchy]="true"
                [customClass]="'new-design'"
                [isRequired]="true"
                [items]="audienceItems"
                [hasSearchBox]="false"
                [searchPlaceholder]="'Find an audience'"
                [hideLabel]="true"
                [clearable]="true"
                [placeholder]="'Select'"
                [isPlaceHolder]="false"
                [isApplyButton]="true"
                [selectedItemCode]="urlaudiencesetData"
                (itemChangedCallback)="getAudience($event)"
                [id]="'audienceId'"
                (applyFilterCallback)="resetFilter($event)"
                [isApply]="isApplied"

                ></amdocs-autocomplete></div>
        </div>
        <div class="d-flex justify-content-between clear-btn clear_btn_section">
            <div data-qa="filter-clear" (click)="clearAllSelections()" class="d-inline"><a class="clear_button">Clear all</a></div>
    <div>
    <button data-qa="filter-cancel" class="cancel_button" (click)="toggleModal()">Cancel</button>
    <button data-qa="filter-apply" class="apply_button" (click)="applyFilter()">Apply</button>
    </div>
    </div>
    </div>          
    </div>
    </div>
