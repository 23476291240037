// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {baseEnvironment, misnpBaseURL} from './environment.base';


export const environment = Object.assign(baseEnvironment, {
  loginGWBaseURL: `${misnpBaseURL}/cas-test`,
  cnsBaseURL: `${misnpBaseURL}/cns-test/graphql`,
  imgUrl: `${misnpBaseURL}/sas/profilePicture`,
  production: false,
  avatarsVersion: '1',
  refreshTokenInterval: 2400000,
  footerConfig:{
    copyrights:'https://www.amdocs.com/copyright',
    wwwAmdocs:'https://www.amdocs.com/'
  },
  headerConfig:{
    supportOne:'https://summitts.amdocs.com/'
  }
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
