  import { Component, ElementRef, Input, OnChanges, ViewChild,Output, EventEmitter } from '@angular/core';
  import { AmdocsEventBusService } from 'amdocs-core-package';
  import { DatePipe } from '@angular/common';
  import { CONSTANTS } from 'src/app/constants';
  import { InitService } from 'src/app/service/init.service';
  import { UserService } from 'src/app/service/user.service';
  import { environment } from 'src/environments/environment';
  import { ActivatedRoute, Router } from '@angular/router';
  import { DownloadDocumentService } from 'src/app/service/downloadDocument.service';
import { catchError, Observable, of, tap } from 'rxjs';
import { TitleService } from 'src/app/service/title.service';
  @Component({
    selector: 'app-page-banner',
    templateUrl: './page-banner.component.html',
    styleUrls: ['./page-banner.component.scss'],
    providers: [DatePipe]
  })
  export class PageBannerComponent implements OnChanges {
    @ViewChild('titlesection') titlesec: ElementRef<HTMLIFrameElement>;
    @Output() metaEvent = new EventEmitter<string>();
    @Input({ required: true }) docsTitle!: string;
    public title: string = '';
    public showMoreProductLines: string = '';
    public assetsPath = CONSTANTS.ASSETS_URL;
    public productLineTitle: string = '';
    public productLineMeta:any={};
    public productLine: string='';
    mailtoLink: string;
    public hrefLink: string;
    public isDocumentAPILoading:boolean=false;
    formattedTitle: string;
    constructor(private eventBus: AmdocsEventBusService, private datePipe: DatePipe,private initService: InitService,private userService: UserService,private router:Router,private route:ActivatedRoute,private downloadService:DownloadDocumentService,private titleService:TitleService) {
      this.route.queryParams.subscribe(params => {
        this.productLine =params['productLine']? decodeURIComponent(params['productLine']):'';
        if(this.productLine){
          sessionStorage.setItem('productLine', this.productLine);
        }
      });

      this.eventBus.on('scrollPos', (data:any) => {
        if (data>0) {
          this.titlesec.nativeElement.style.display='none';

        }else{
          this.titlesec.nativeElement.style.display='block';

        }
      }, true);
    }


    ngOnChanges() {

      if (this.docsTitle) {
        this.setTitle(this.docsTitle['KIN']);
        if(!sessionStorage.getItem('productLine')){
          sessionStorage.setItem('productLine',this.docsTitle?.['PL']?.[0]);
        }
        this.productLineTitle =sessionStorage.getItem('productLine');
        this.getProductLineMetaData(this.productLineTitle)// Use trim() to remove any leading/trailing whitespace
      }
      if (this.docsTitle && this.docsTitle['PL']) {
        this.showMoreProductLines = this.headingFormatter(
          this.docsTitle['APrdctTre'],
          '>',
          ' | ',
          'NA',
          '\n'
        );
        const heading = this.headingFormatter(
          [this.docsTitle['APrdctTre'][0]],
          '>',
          ' | ',
          'NA'
        );
        const lastUpdatedDate = this.docsTitle['LstUpdt'].length
          ?
            this.dateFormatter(this.docsTitle['LstUpdt'], 'dd MMM yyyy', true)  + ' | '


          : '';
        this.title = lastUpdatedDate + heading  ;
        if(this.title ){
          this.formattedTitle = this.title.replace(/\|/g, ' | ');
        }

      }
  }

  public getProductLineMetaData(productLine):void {
    this.initService
    .getProductLineMetaData({
      User_ID: this.userService.getEmail(),
      Emp_ID: this.userService.getEmployeeCode(),
      SO_Session_ID : "",
      IP_Details : "",
      DaC_Session_ID :environment.dacSessionId,
    })
    .subscribe((ProductLines) => {
      if(ProductLines){
        let filteredItems = ProductLines['getProductLineMetaData'];
        let matcheddata = this.getMatchProductLine(filteredItems);
        if(matcheddata){
          this.productLineMeta=matcheddata;
        }
      }

    });
  }

  navigateToProductLine(){
    this.eventBus.emit('pageloader', true);
    this.router.navigate(['/productline'], {replaceUrl: true, state: { productLine: this.productLineMeta } });
  }

  navigatetoHome(){
    this.router.navigate(['/'], {replaceUrl: true});
  }

  public getMatchProductLine(data) {
    for (const category in data) {
      if (Array.isArray(data[category])) {
        for (const item of data[category]) {
          if (item.values && Array.isArray(item.values)) {
            for (const value of item.values) {
              if (value.name === this.productLineTitle) {
                return value;
              }
            }
          }
        }
      }
    }
    return undefined; // Return undefined if no match is found
  }

  /**
    * @description This function is used to format the heading
    * @param sourceString
    * @param stringToBeReplace
    * @param replaceWith
    * @param eliminateText
    * @param stringAppendedAfterFormat
    * @returns Formatted String of heading
    */
  headingFormatter(sourceString: [string], stringToBeReplace: string='', replaceWith: string='', eliminateText: string='', stringAppendedAfterFormat: string=''): string{
    try{return sourceString.map((productLine) =>
      productLine.split(stringToBeReplace).filter((str) => str !== eliminateText)
    )
    .map((arr) => arr.join(replaceWith))
    .map((value, index) => {
    return index < (sourceString.length - 1) ? (value += ',') : value;
    })
    .join(stringAppendedAfterFormat);}      // Join all the records with new line
    catch{
      return '';
    }
  }

  download(): void {
    let item: any = this.docsTitle;
    this.isDocumentAPILoading = true;
    const documentId = item?.DocNum;
    const documentName = item?.KIN[0];

    this.downloadService.getDownload(documentId)
      .pipe(
        tap((response: any) => this.handleApiResponse(response, documentName)),
        catchError(error => this.handleApiError(error))
      )
      .subscribe();
  }

  handleApiResponse(response: any, documentName: string): void {
    const data = response.body;
    if (data.getDocumentContent.ContentResponse) {
      const byteArray = this.convertByteStreamToBlob(data.getDocumentContent.ContentResponse.FileByteStream);
      const mimeType = 'application/zip';
      const documentext = 'zip';
      this.createDownloadLink(byteArray, mimeType, documentName, documentext);
      this.isDocumentAPILoading = false;
    } else {
      this.isDocumentAPILoading = false;
    }
  }


  handleApiError(error: any): Observable<null> {
    this.isDocumentAPILoading = false;
    console.error('Error:', error);
    return of(null); // Return a fallback value or empty observable
  }


  convertByteStreamToBlob(byteStream: string): Uint8Array {
    const byteCharacters = atob(byteStream);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  }

  createDownloadLink(byteArray: Uint8Array, mimeType: string, documentName: string, documentext: string): void {
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${documentName}.${documentext}`; // Set the file name and extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl); // Clean up the URL
  }

  setTitle(title): void {
    const staticTitle = 'Document Page'; // Set your static title here
    this.titleService.setTitle(title?title:staticTitle);
  }

  /**
    * @description This function is used to format the date
    * @param date
    * @param format
    * @param isInputMMDDYYYY
    * @returns Formatted Date
    */
  dateFormatter(date: string, format :string='dd mm yyyy', isInputMMDDYYYY: boolean = true): string{
    if(isInputMMDDYYYY){
      const splitDate = date.split('/');
      date = `${splitDate[1]}\/${splitDate[0]}\/${splitDate[2]}`;
      const transformedDate = this.datePipe.transform(new Date(date), format);
      const formattedDate = transformedDate.replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());
      return formattedDate;
    }
    return this.datePipe.transform(new Date(date), format);
  }
  shareCourse() {
    this.hrefLink = window.location.origin;
    const docID = encodeURIComponent(this.docsTitle['UnqI']);  // encoding the data.UnqI value
    const subject = 'Document(s) from the Amdocs documentation portal'
    const body = encodeURIComponent(`Hi,

The following document(s) from the Amdocs documentation portal might interest you:

${this.docsTitle['KIN']}
${this.hrefLink}/document?DocUID=${docID}&SOSessionID=&StartPage=`);
      this.mailtoLink = `mailto:?subject=${subject}&body=${body}`;   }
  }
