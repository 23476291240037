<div
  class="autocomplete-wrapper {{ customClass }}"
  [ngClass]="{ 'has-hierarchy': hasHierarchy, rounded: isRounded }"
>
  <div
    *ngIf="!hideLabel"
    class="custom-input-label text-color-secondary autocomplete-label d-flex"
  >
    {{ label }}
    <span *ngIf="isRequired" class="required mr-1 align-middle">&nbsp;*</span>
    <ng-container *ngIf="hasTooltip && tooltipContent">
      <lib-amdocs-popover [trigger]="'hover'" [placement]="'right'">
        <img popoverOpener alt="infoicon" class="info-icon" src="assets/icons/info.svg" />
        <div popoverContent>{{ tooltipContent }}</div>
      </lib-amdocs-popover>
    </ng-container>
  </div>
  <ng-select
    #mySelect
    bindLabel="name"
    bindValue="name"
    class="select-wrapper"
    [className]="selectedItemsDisplayPosition"
    [items]="filteredItems"
    [multiple]="isMultiple"
    [readonly]="readOnly"
    [(ngModel)]="selectedItemCode"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [closeOnSelect]="false"
    (clear)="selectedItemCleared.emit()"
    (open)="updateCheckboxStates()"
    [disabled]="disabled"
    [searchable]="false"
    [clearOnBackspace]="false"
    [searchFn]="onSearch"
    [isOpen]="isNotOpen ? false : null"
    (change)="itemChangedEvent($event)"
    [virtualScroll]="virtualScroll"
  >

    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-search="searchTerm"
      let-index="index"
    >
      <div
        [ngClass]="{
          'is-group': item.isGroup,
          'highlight-result': highlightResults
        }"
        class="item"
      >
        <div
          *ngIf="!autocompleteOptionTemplate && isMultiple"
          class="text-small-normal"
          [ngClass]="{
            'd-flex align-items-center justify-content-start': isMultiple
          }"
        >
          <ng-container>
            <input
            [ngModel]="item$.selected"
            (change)="onCheckboxClick()"
            type="checkbox"
            #checkbox
            [checked]="item$.selected"
            class="custom-control-input"
            [id]="'drop-down-multiple-item-' + index"
            />
            <span
              class="
                custom-control-indicator
                align-items-center
                d-flex
                justify-content-center
              "
            ></span>
          </ng-container>
          {{ item.name }}
        </div>

        <div
          *ngIf="!autocompleteOptionTemplate && !isMultiple"
          class="text-small-normal"
          [ngOptionHighlight]="search"
          [ngClass]="{
            'd-flex align-items-center justify-content-start': isMultiple
          }"
        >
          {{ item.name }}
        </div>

        <ng-container *ngIf="autocompleteOptionTemplate">
          <ng-template
            ngFor
            let-item
            [ngForOf]="[item]"
            [ngForTemplate]="autocompleteOptionTemplate"
          ></ng-template>
        </ng-container>
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items">
      <div *ngIf="isPlaceHolder">{{placeholder}}</div>
      <div [hidden]="isPlaceHolder"
        class="ng-value"
        *ngFor="let item of items | slice: 0:1"
        [ngClass]="{ 'multi-label': items.length > 1 }"
      >
        <span *ngIf="items.length <= 1" class="multi-count-content">
          <span class="ng-value-label">{{ item.name }}</span>
        </span>
        <span *ngIf="items.length > 1" class="ng-value-label options"
          >{{getValidItemCount(filteredItems,items)}} {{ "selected" }}</span
        >
        <span
        class="ng-value-icon left"
        (click)="clear(item)"
        aria-hidden="true"
        ><img alt="tags-remove" src="/assets/icons/tags-remove.svg"></span
      >
      </div>
    </ng-template>

    <ng-template ng-header-tmp *ngIf="hasSearchBox">
      <div class="search-input-wrapper">
        <lib-amdocs-input-field
          [elementId]="'searchPlan'"
          [placeHolder]="searchPlaceholder"
          [control]="searchPlanFC"
          [hideLabel]="true"
          (input)="onInputSearch($event.target.value)"
          [(ngModel)]="searchQuery"
          #searchInput
        >
        </lib-amdocs-input-field>
        <img
          class="find-an-item-icon"
          lib-amdocs-image-version
          src="/assets/icons/search.svg"
          alt="search"
        />
      </div>
      <hr *ngIf="filteredItems && filteredItems.length > 0">
      <div  class="selectionAll" *ngIf="filteredItems && filteredItems.length > 0">
        <label class="custom-checkbox">
          <input  type="checkbox"  [ngModel]="isAllChecked"  [checked]="isAllChecked" #selectAll (click)="triggerClickOnAllCheckboxes($event.target.checked)" />
          <span class="checkmark"></span>All
        </label>
      </div>
    </ng-template>
    <ng-template ng-footer-tmp>
      <div (click)="clear()" class="d-inline"><a  style="color: #0070C9; font-weight: 500; font-size: 12px; cursor: pointer;">Clear selection</a></div>
      <div>
      <button class="cancelButton" (click)="onSelectClose()"
      >Cancel</button>
      <button *ngIf="isApplyButton"  class="applyButton" (click)="applyFilterSearch(id)"
      >Apply</button>
      </div>
    </ng-template>
  </ng-select>

</div>
